import { Outlet } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navigation from "./Navigation";

export default function Layout() {
  return (
    <>
    <Navigation />
      <Container className="x">
        <Outlet/>
      </Container>
    </>
  )
}
