import "./styling/css/default.css"
import ThemeProvider from 'react-bootstrap/ThemeProvider';

import { Routes, Route} from 'react-router-dom';

import Layout from './components/Layout';
import Frontpage from './pages/Frontpage';
import TOS from "./pages/TOS";
import Commissions from "./pages/Commissions";
import SocialProfiles from "./pages/SocialProfiles";
import OrderForm from "./pages/OrderForm";

function App() {
  return (
    <ThemeProvider
     breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
     minBreakpoint="xxs"
    >
      <div className="App">
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Frontpage/>}/>
            <Route path="/tos" element={<TOS/>}/>
            <Route path="/commissions" element={<Commissions/>}/>
            <Route path="/socials" element={<SocialProfiles/>}/>
            <Route path="/orderform" element={<OrderForm/>}/>
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}
export default App;
