import "../styling/css/nav.css"

import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function Navigation() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Link to="/" className="navbar-brand">KizuNova</Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {/* <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="me-auto">
            <Link to="/" className="nav-link" tabIndex="0">Home</Link>
            <Link to="/socials" className="nav-link" tabIndex="0">Socials</Link>
            <NavDropdown title="Commissions" id="basic-nav-dropdown">
              <Link to="/commissions" data-rr-ui-dropdown-item className="dropdown-item">Commissions 1</Link>
              <NavDropdown.Divider />
              <Link to="/tos" data-rr-ui-dropdown-item className="dropdown-item">Terms of Service</Link>
            </NavDropdown>
            <Link to="/contact" className="nav-link" tabIndex="0">Contact</Link>
          </Nav>
        </Navbar.Collapse> */}
        <Navbar.Collapse className="justify-content-end">
          <Nav>
              <Link to="/" className="nav-link" tabIndex="0">Home</Link>
              <Link to="/socials" className="nav-link" tabIndex="0">Socials</Link>
              <NavDropdown title="Commissions" id="basic-nav-dropdown">
                <Link to="/commissions" data-rr-ui-dropdown-item className="dropdown-item">Commissions</Link>
                <NavDropdown.Divider />
                <Link to="/tos" data-rr-ui-dropdown-item className="dropdown-item">Terms of Service</Link>
                <Link to="/orderform" data-rr-ui-dropdown-item className="dropdown-item">Order Form</Link>
              </NavDropdown>
              {/* <Link to="/contact" className="nav-link" tabIndex="0">Contact</Link> */}
            </Nav>
        </Navbar.Collapse>
      </Container>
  </Navbar>
  )
}
