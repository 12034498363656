export default function TOS() {
  document.title = `Terms of Service`
  return (
    <>
    <div className="front">
      <section className="left">
        <ul>
          <h3>What you can expect</h3>
          <li>
            I can draw any sort of animal or fantasy creature, Anthros included. (Wolf-alike creatures are my strongest feature)
          </li>
          <li>
            I can and will decline any overly complex designs, I'm okay with most accessories and additional body parts, but when the markings on your character is too messy and hard to keep track off, it will reflect on the quality of the artwork, hence I'm not comfortable completing your commission. In other words, you'll get a decline. 
          </li>
          <li>
            I do not draw NSFW, so I do not offer NSFW commissions. (Mild gore might be alright, depending, no sexual themes)
          </li>
          <li>
            I can do any sort of environment but my background skills are being refined. 
          </li>
          <li>
            You will receive a full resolution version of your commission once it's done. 
          </li>
          <li>
            I experiment a lot with my artwork, if you want a very specific style of mine, let me know and I'll do my best to mimic this, but I cannot guarantee that I will be able to replicate any art style and I might refuse to do some if they're too old or I don't like them
          </li>
          <li>
            I still own the right to the artwork, but I allow you to use it however you desire, granted you don't claim the artwork as your own. 
          </li>
          <li>
            Artwork created for you will not be used without your permission (Say for merchandise or similar) 
          </li>
        </ul>
      </section>
      <section className="left">
        <ul>
          <h3>Payment and Conditions</h3>
          <li>
            Payment will be trough Paypal and completed with Invoice (Hence I'll need your PayPal Info).
          </li>
          <li>
            I will not send out the invoice before you have approved the sketch, after this point, no additional changes can be made by your request.
          </li>
          <li>
            For larger payments, I may offer a payment plan, this will be discussed as we figure out the details of the commission.
          </li>
          <li>
            Sketches and/or artwork by me shall not be used to train AI or sold as NFT's, this will result in a blacklist from me and I will snitch on you.. Yes, I'm that petty.
          </li>
          <li>
            You may: Use the final artwork as icon/banner anywhere and re-post as long as credit is given. You may also use the artwork to design and purchase 
            any sort of merchandise.
          </li>
          <li>
            You may <strong>not</strong>: Re-sell the artwork or feed it into any sort of AI (machine learning) or sell it as NFT.
          </li>
          <li>
            In a scenario where I'm unable to complete a commission or has taken too long to complete a commission, I will offer to refund the partial amount you paid for your commission, depending on how far into the process I am.
          </li>
          <li>
            This will occur if I've spend several months (2+) to complete and you wish for your money back, or if I've personally concluded I will not be able to complete the commission in a reasonable time frame. 
          </li>
          <li>
            I serve the right to decline any commissions and you serve the right to cancel the commission at any given time, but refund will very depending on how much work has been put into your artwork
          </li>
        </ul>
      </section>
    </div>
    </>
  )
}
