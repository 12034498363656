import "../styling/css/front.css";

import { Link } from 'react-router-dom';

export default function Frontpage() {
  document.title = `KizuNova`

  return (
    <>
    
      <div className="front">
        <section className="left">
          <img 
            src="https://f2.toyhou.se/file/f2-toyhou-se/images/63414136_y7D3fDe2tjuzFYM.png" 
            alt="Wolf Dan drawing"
            className="homeimage"
            />
        </section>
        <section className="right">
        <div className="rightTop">
          <h1 className="homeintro">Hello There!</h1>
          <div className="homeInfo">
            <p>
              My name is Kizu and I'm an artist from Norway born in 1998. I live together with my fiancé SinCelticus and our fuzzy kittens.</p>
            <p>
              I discovered that I enjoyed drawing at a very young age, and since around 2012 I've been doing artwork digitally.
              In the recent years I've been exploring the concepts of programming as well and has become sufficient in this.</p>
            <p>
              I wish to develop as an artist and possibly share my art with other artists and other people who enjoy art!<br/>
              My commissions are open for anyone and can be found <Link to="/commissions" className="infoLink">Here</Link>!
            </p>
          </div>
        </div>
        <div className="rightBottom">
          <a href='https://ko-fi.com/U6U411Q4L' target='_blank' rel="noreferrer">
            <img height='36' className="kofi" src='https://storage.ko-fi.com/cdn/kofi3.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' />
          </a>
          <p>
            Say no to NFT and AI 'art', keep handmade artwork alive!
          </p>
        </div>
        </section>          
      </div>
    </>
  )
}
