import "../styling/css/commissions.css";

export default function Commissions() {
  document.title = `Commissions`
  return (
    <>
      <div className="status">
        <span className="open"></span>
      </div>
      Head <a href="https://artistree.io/request/kizunova" className="infoLink">here</a> for temporary commission info or send a discord message to KizuNova
    </>
  )
}
