import "../styling/css/socialProfiles.css";

import Profile from "../components/Profile";

export default function SocialProfiles() {
  document.title = `Social Profiles`
  return (
    <>
    <div className="container text-center profiles">
      <img src="https://kizunova.carrd.co/assets/images/image03.png?v=c7ebec8c" alt="icon" className="icon" title="Hello there, I would appreciate a follow on any of my profiles below!"/>
    </div>
      <div className="container text-center profiles">
        {/* Check out my other social media accounts!  */}
        <div className="row">
          <Profile title="Twitter" link="https://twitter.com/Kizu_Nova"/>
          <Profile title="Bluesky" link="https://bsky.app/profile/kizunova.bsky.social"/>
          <Profile title="Ko-Fi" link="https://ko-fi.com/kizunova"/>
          <Profile title="Toyhou.se" link="https://toyhou.se/KizuNova"/>
          <Profile title="YouTube" link="https://www.youtube.com/c/kizunova"/>
          <Profile title="Inkblot" link="https://inkblot.art/profile/bOcQOHaehie8ZacFtaqriOnRkdB2"/>
          <div className="w-100"></div>
          {/* <Profile title="DeviantArt" link="https://www.deviantart.com/kizunova"/> */}
          <Profile title="Discord Server" link="discord.gg/QBBRj8dZRQ"/>
        </div>
      </div>
    </>
  )
}
